<template>
  <div class="login-wrap">
    <!-- 로그인 네모 박스 -->
    <div class="login-container">
      <h2 class="login-title">보리앱 병원관리자 시스템</h2>

      <div class="inp-row">
        <label for="memberID" class="is-blind">아이디입력</label>
        <div class="login-inp-box">
          <input type="text" id="memberID" ref="memberID" class="login-inp-txt" placeholder="아이디 입력" required
                 autofocus
                 v-model="memberID">
          <button class="login-inp-del"><span class="is-blind">삭제</span></button>
        </div>
        <!-- 입력에러시 -->
        <p class="error-msg">아이디를 확인해주세요.</p>
      </div>

      <!-- is-error 에러 표시 -->
      <!-- <div class="inp-row is-error">
          <label for="memberID" class="is-blind">아이디입력</label>
          <div class="login-inp-box">
              <input type="text" id="memberID" class="login-inp-txt" placeholder="아이디 입력">
              <button class="login-inp-del is-active"><span class="is-blind">삭제</span></button>
          </div>
          <p class="error-msg">아이디를 확인해주세요.</p>
      </div> -->

      <div class="inp-row">
        <label for="password" class="is-blind">패스워드입력</label>
        <div class="login-inp-box">
          <input type="password" id="password" ref="password" class="login-inp-txt" placeholder="패스워드 입력" required
            v-model="password"
          />
          <!-- is-active 표시 -->
          <button class="login-inp-view is-active"><span class="is-blind">비밀번호 표시</span></button>
        </div>
        <!-- 입력에러시 -->
        <p class="error-msg">패스워드를 확인해주세요.</p>
      </div>

      <!-- <div class="inp-row is-error">
          <label for="password" class="is-blind">패스워드입력</label>
          <div class="login-inp-box">
              <input type="password" id="password" class="login-inp-txt" placeholder="패스워드 입력">
              <button class="login-inp-view is-active"><span class="is-blind">비밀번호 표시</span></button>
          </div>
          <p class="error-msg">패스워드를 확인해주세요.</p>
      </div> -->

      <button class="btn-login" @click="login()"><span class="txt" >로그인</span></button>
    </div>
    <!-- // 로그인 네모 박스 -->
  </div>
</template>
<style>
</style>
<script>
export default {
  name: "Login",
  data: () => ({
    memberID: null,
    password: null
  }),
  mounted() {
    this.$refs["memberID"].focus();
  },
  methods: {
    login() {

      this.$axios
        .post("login-hospital", {
          email: this.memberID,
          password: this.password
        })
        .then(response => {
          if (response.data.success ){
            this.storeName(response.data.data.name);
            this.storeToken(response.data.data.token);
            this.$router.push({
              name: 'Application',
            });

          }

        })
        .catch(ex => {
          console.log(ex);
          this.$alert.show("로그인이 실패했습니다.");
          //this.$alert.show(ex.message);
        });
    },
    storeName(name) {
      this.$session.set("name", name);
    },
    storeToken(token) {
      this.$session.set("token", token);
      this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    }
  }
};
</script>
